import React, { useEffect, useState } from 'react';
import "./resetDataCSS.scss";
import { awsConfig } from '../constants/defaultValues';

const ResetData = () => {
    const [email, setEmail] = useState(null);
    const [message, setMessage] = useState(null);
    const [data, setData] = useState(null);
    const [deleteStatus, setDeleteStatus] = useState(true);

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${awsConfig.API.endpoints[0].endpoint}/searchEmail?email=${encodeURIComponent(email)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const result = await response.json();

                // Assuming the structure is { results: { dataResult: [...] } }
                if (result.results && result.results.dataResult) {
                    setData(result.results.dataResult);
                } else {
                    setMessage('Error: Data result not found');
                }
            } else {
                setMessage('Error: ' + response.statusText);
            }
        } catch (error) {
            setMessage('Error: ' + error.message);
        }
    };

    const deleteData = async (id, email) => {
        const isConfirmed = window.confirm(`คุณเเน่ในหรือที่ต้องการลบ video เเละ คะเเนน ของ ${email} จะถูกลบทั้งหมด`);
        if (isConfirmed) {
            setDeleteStatus(false);
            try {
                const response = await fetch(`${awsConfig.API.endpoints[0].endpoint}/deleteData?id=${encodeURIComponent(id)}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const result = await response.json();

                    setMessage("ลบข้อมูลทั้งหมดเรียบร้อย")
                    setData(null);
                    setDeleteStatus(true);


                    // Assuming the structure is { results: { dataResult: [...] } }

                } else {
                    setMessage('Error: ' + response.statusText);
                }
            } catch (error) {
                setMessage('Error: ' + error.message);
            }
        }
    }


    useEffect(() => {
        if (message != null) {
            setTimeout(() => {
                setMessage(null);

            }, 3000);
        }

    }, [message])
    console.log("awsConfig", awsConfig.API.endpoints[0].endpoint);

    return (
        <div className='box-reset-data'>


            <p className='message-delete'>ข้อมูลเกี่ยว video เเละ คะเเนน ของ user จะถูกลบทั้งหมด</p>
            <p className='message-delete-data'>{message}</p>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-8">
                        <input
                            className="form-control me-2"
                            type="search"
                            placeholder="Email"
                            aria-label="Search"
                            value={email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </div>
                </div>
            </form>

            {data && data.length > 0 ? (
                data.map(item => (
                    /*   <div key={item.user_id}>
                          {item.user_id} {item.display_name}
                      </div> */
                    <table class="table mt-5">
                        <thead>
                            <tr>
                                <th scope="col">User Id</th>
                                <th scope="col">Email</th>
                                <th scope="col">ชื่อ</th>
                                <th scope="col">นามสกุล</th>
                                <th scope="col">fb_group</th>
                                <th scope="col">facebook</th>
                                <th scope="col">display_name</th>
                                <th scope="col">ลบข้อมูล</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={item.user_id}>
                                <th scope="row">{item.user_id}</th>
                                <td>{item.email}</td>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.fb_group}</td>
                                <td>{item.facebook}</td>
                                <td>{item.display_name}</td>
                                <td>
                                    <button type="button" className={deleteStatus ? "btn btn-danger" : "btn btn-secondary"} onClick={() => deleteStatus && deleteData(item.user_id, item.email)}>ลบข้อมูล</button>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                ))
            ) : (
                <p className='mt-5'>No data available</p>
            )}
        </div>
    );
};

export default ResetData;
